<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-row class="bradcrumb">
          <v-col cols="6" md="6" sm="6">
            <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
            <span class="child"> Librarian / Add Book </span>
          </v-col>
          <v-col cols="6" md="6" sm="6">
              <router-link style="float:right;" :to="{ name: 'import-bookitem-new'}">
                <v-btn class="mb-2 button">Import Book Item</v-btn>&nbsp;&nbsp;
              </router-link>
              <router-link style="float:right;" :to="{ name: 'import-book-new'}">
                <v-btn class="mb-2 button">Import Book</v-btn>&nbsp;&nbsp;
              </router-link>
          </v-col>
        </v-row>

        <v-card class="card">
            <v-card-title class="heading justify-center">
                Add Book
            </v-card-title>
            <v-card-text>
                <v-row style="" class="pt-5">
                    <v-col cols="12" md="3" sm="3">
                        <v-autocomplete v-model="selectedseries" dense outlined label="Series" :items="serieslist" item-value="seriesId" item-text="seriesName"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                       <v-autocomplete v-model="selecteddepartment" dense outlined label="Department" :items="departmentlist" item-text="name" item-value="id"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                        <v-autocomplete v-model="selectedpublisher" dense outlined label="Publisher D" :items="publisherlistsearch" item-value="publisherId" item-text="publisher"  @input.native="publishersearch($event.srcElement.value)"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                        <v-autocomplete v-model="selectedbooktype" dense outlined label="Book Type" :items="booktypelistsearch" item-value="bookTypeId" item-text="bookType"></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3" sm="3">
                        <v-combobox style="padding: 0px; margin: 0px" label="Book Title" v-model="searchtitle" :items="allBookInfo" item-text="displayname" outlined hide-no-data hide-details dense @input.native="titlesearch($event.srcElement.value)"></v-combobox>                            
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                        <v-text-field v-model="accno" dense outlined label="Accession No"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                        <v-text-field v-model="isbnsearch" dense outlined label="ISBN"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pb-3" justify="center" align="center">
                    <v-btn class="m-2" color="info" @click="searchByA_no">Search</v-btn>
                </v-row>
                <v-data-table :headers="headers" :items="bookList" class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details></v-text-field>
                            <span style="width: 20px"></span>
                            <v-btn class="mb-2 button" @click="addBook">Add Book</v-btn>
                            <v-dialog v-model="dialog" max-width="1200px">
                                <v-card>
                                    <v-form v-model="valid" ref="form">
                                        <v-card-title class="heading">
                                            <v-row>
                                                  <v-col cols="11" md="11" sm="11">
                                                      {{ formTitle }} 
                                                  </v-col>
                                                  <v-col cols="1" md="1" sm="1">
                                                    <v-btn icon dark @click="close">
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                  </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row >
                                                    <v-col cols="12" sm="12" md="12" lg="12" v-if="isImg">
                                                        <center>
                                                            <v-img height="150" width="150" :src="img"></v-img>
                                                        </center>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Library</label>
                                                        <span style="color: red; font-weight: bolder">*</span>
                                                        <v-autocomplete dense outlined :items="libraryList" item-value="id" item-text="name" v-model="editedItem.library" persistent-hint :error="errorMsg.library ? true : false" :error-messages="errorMsg.library ? 'Required' : ''" :rules="[(v) => !!v || 'required']"></v-autocomplete>
                                                    </v-col>
                                                    <v-col v-if="!inSave" cols="12" sm="12" md="4" lg="4">
                                                        <label>Book Series</label>
                                                        <v-autocomplete dense outlined :items="bookSeriesList" item-value="seriesId" item-text="seriesName" v-model="editedItem.seriesId" persistent-hint></v-autocomplete>
                                                    </v-col>
                                                    <v-col v-else cols="12" sm="12" md="4" lg="4">
                                                        <label>Book Series</label>
                                                        <v-autocomplete dense outlined :items="bookSeriesList" item-value="seriesId" item-text="seriesName" v-model="editedItem.seriesId" persistent-hint @change="getavalableisbn()"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>ISBN</label>
                                                        <span style="color: red; font-weight: bolder">*</span>
                                                        <v-text-field dense outlined v-model="editedItem.isbn" item-text="roleLink_list_id" item-value="id" persistent-hint :error="errorMsg.isbn ? true : false" :error-messages="errorMsg.isbn ? 'Required' : ''" :rules="[(v) => !!v || 'required']" @blur="fetchBookInfo()"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Language</label>
                                                        <v-autocomplete dense outlined :items="languageList" item-value="languageId" item-text="language" v-model="editedItem.languageId" persistent-hint></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Title</label>
                                                        <v-icon color="blue" class="mr-2" @click="title_dialog = true">mdi-plus-box</v-icon>
                                                        <v-autocomplete :items="allBookInfo" item-text="displayname" item-value="blfid" v-model="editedItem.titleid" persistent-hint @input.native="searchTitleDropdown($event.srcElement.value)"></v-autocomplete>
                                                    </v-col>
                                                    <v-dialog v-model="title_dialog" width="500">
                                                        <v-card>
                                                            <v-card-title class="grey lighten-2">Add Title</v-card-title>
                                                            <v-card-text style="padding-bottom: 0px">
                                                                <label class="add-text">TiTle</label>
                                                                <span style="color: red; font-weight: bolder">*</span>
                                                                <v-text-field class="text" v-model="titlename" outlined dense clearable required></v-text-field>
                                                            </v-card-text>
                                                            <div class="text-center">
                                                                <v-spacer></v-spacer>
                                                                <v-btn class="m-3" color="error" @click="title_dialog = false">Close</v-btn>
                                                                <v-btn class="m-3" color="success" @click="addTitle()">Add</v-btn>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>

                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Description</label>
                                                        <v-textarea dense outlined rows="2" v-model="editedItem.description" item-text="roleLink_list_id" item-value="id" persistent-hint></v-textarea>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" v-if="!book_authors">
                                                        <label>Author(s)</label>
                                                        <v-icon color="blue" class="mr-2" @click="authordialog = true">mdi-plus-box</v-icon>
                                                        <v-autocomplete dense outlined :items="authorList" item-value="authorId" item-text="author" v-model="editedItem.authors" persistent-hint multiple></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" v-else>
                                                        <label>Author(s)</label>
                                                        <v-icon color="blue" class="mr-2" @click="authordialog = true">mdi-plus-box</v-icon>
                                                        <v-text-field dense outlined v-model="editedItem.authors"></v-text-field>
                                                    </v-col>
                                                    <v-dialog v-model="authordialog" width="500">
                                                        <v-card>
                                                            <v-card-title class="grey lighten-2">Add Author</v-card-title>
                                                            <v-card-text style="padding-bottom: 0px">
                                                                <label class="add-text">Author</label>
                                                                <span style="color: red; font-weight: bolder">*</span>
                                                                <v-text-field class="text" v-model="authorName" outlined dense clearable required></v-text-field>

                                                                <label class="add-text">Description</label>
                                                                <v-textarea class="text" v-model="author_description" outlined dense rows="2"></v-textarea>
                                                            </v-card-text>
                                                            <div class="text-center">
                                                                <v-spacer></v-spacer>
                                                                <v-btn class="m-3" color="error" @click="authordialog = false">Close</v-btn>
                                                                <v-btn class="m-3" color="success" @click="addAuthor()">Add</v-btn>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-col cols="12" sm="12" md="4" lg="4" v-if="!publisherText">
                                                        <label>Publisher</label>
                                                        <v-icon color="blue" class="mr-2" @click="publisher_dialog = true">mdi-plus-box</v-icon>
                                                        <v-autocomplete dense outlined :items="publisherList" item-value="publisherId" item-text="publisher" v-model="editedItem.publisherId" persistent-hint  @input.native="publishersearch($event.srcElement.value)"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" v-else>
                                                        <label>Publisher</label>
                                                        <v-icon color="blue" class="mr-2" @click="publisher_dialog = true">mdi-plus-box</v-icon>
                                                        <v-text-field dense outlined v-model="editedItem.publisherId" persistent-hint></v-text-field>
                                                    </v-col>
                                                    <v-dialog v-model="publisher_dialog" width="500">
                                                        <v-card>
                                                            <v-card-title class="grey lighten-2">Add Publisher</v-card-title>
                                                            <v-card-text style="padding-bottom: 0px">
                                                                <label class="add-text">Publisher</label>
                                                                <span style="color: red; font-weight: bolder">*</span>
                                                                <v-text-field class="text" v-model="publishername" outlined dense clearable required></v-text-field>
                                                                <label class="add-text">Short Name</label>
                                                                <span style="color: red; font-weight: bolder">*</span>
                                                                <v-text-field class="text" v-model="publisher_short_name" outlined dense rows="2"></v-text-field>
                                                            </v-card-text>
                                                            <div class="text-center">
                                                                <v-spacer></v-spacer>
                                                                <v-btn class="m-3" color="error" @click="publisher_dialog = false">Close</v-btn>
                                                                <v-btn class="m-3" color="success" @click="addpubliasher()">Add</v-btn>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Publication Place</label>
                                                        <v-text-field dense outlined v-model="editedItem.place" item-text="roleLink_list_id" item-value="id" persistent-hint ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Volume</label>
                                                        <v-text-field dense outlined v-model="editedItem.volume" item-text="roleLink_list_id" item-value="id" persistent-hint type="number"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Edition</label>
                                                        <v-icon color="blue" class="mr-2" @click="edition_dialog = true">mdi-plus-box</v-icon>
                                                        <v-autocomplete :items="editionlist" item-text="edition" item-value="id" v-model="editedItem.editionid" persistent-hint  @input.native="editionsearch($event.srcElement.value)"></v-autocomplete>
                                                    </v-col>
                                                    <v-dialog v-model="edition_dialog" width="500">
                                                        <v-card>
                                                            <v-card-title class="grey lighten-2">Add Edition</v-card-title>
                                                            <v-card-text style="padding-bottom: 0px">
                                                                <label class="add-text">Edition</label>
                                                                <span style="color: red; font-weight: bolder">*</span>
                                                                <v-text-field class="text" v-model="editionname" outlined dense clearable required></v-text-field>
                                                            </v-card-text>
                                                            <div class="text-center">
                                                                <v-spacer></v-spacer>
                                                                <v-btn class="m-3" color="error" @click="edition_dialog = false">Close</v-btn>
                                                                <v-btn class="m-3" color="success" @click="addEdition()">Add</v-btn>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Edition Year</label>
                                                        <v-text-field dense outlined v-model="editedItem.year" item-text="roleLink_list_id" item-value="id" persistent-hint type="number"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Classification/Call No</label>
                                                        <v-text-field dense outlined v-model="editedItem.cno" item-text="roleLink_list_id" item-value="id" persistent-hint ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Book Type</label>
                                                        <v-autocomplete dense outlined :items="bookTypeList" item-value="bookTypeId" item-text="bookType" v-model="editedItem.bookTypeId" persistent-hint></v-autocomplete>
                                                    </v-col>  
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Keywords</label>
                                                        <v-text-field dense outlined v-model="editedItem.keywords" item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Program</label>
                                                        <v-autocomplete class="text" item-text="name" item-value="id" :items="program_list" v-model="editedItem.program" dense outlined></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <label>Book Category</label>
                                                        <v-autocomplete dense outlined :items="bookCatList" item-value="bookCatId" item-text="bookCat" v-model="editedItem.bookCatId" persistent-hint></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                                            <v-btn class="button" @click="save">Save</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="editcopydialog" max-width="1200px">
                                <v-card>
                                    <v-form v-model="valid" ref="form">
                                        <v-card-title class="heading">
                                            <v-row>
                                                  <v-col cols="11" md="11" sm="11">
                                                      {{ formTitle }} 
                                                  </v-col>
                                                  <v-col cols="1" md="1" sm="1">
                                                    <v-btn icon dark @click="bookitemclose">
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                  </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row >
                                                    <v-col cols="12" sm="12" md="12" lg="12" v-if="isImg">
                                                        <center>
                                                            <v-img height="150" width="150" :src="img"></v-img>
                                                        </center>
                                                    </v-col>
                                                    <v-col v-if="editedItem.acc && inSave" cols="12" sm="12" md="4" lg="4">
                                                      <label>Accesation NO</label>
                                                      <span style="color: red; font-weight: bolder">*</span>
                                                      <v-text-field dense outlined v-model="editedItem.acc" item-text="roleLink_list_id" item-value="id" persistent-hint readonly disabled :error="errorMsg.acc ? true : false" :error-messages="errorMsg.acc ? 'Required' : ''" :rules="[(v) => !!v || 'required']" @blur="fetchBookInfo()"></v-text-field>
                                                    </v-col>
                                                    <v-col style="padding-top:0px;" cols="12" sm="12" md="4" lg="4">
                                                      <label>Number of Copies</label>
                                                      <span v-if="inSave" style="color: red; font-weight: bolder">*<br>Present Copies : {{editedItem.copies}}</span>
                                                      <v-text-field v-if="inSave" dense outlined v-model="editedItem.editcopies" item-text="roleLink_list_id" item-value="id" persistent-hint type="number" :readonly="!inSave" :disabled="!inSave" :error="errorMsg.copies ? true : false" :error-messages=" errorMsg.copies ? 'Required' : ''" :rules="[(v) => !!v || 'required']"></v-text-field>
                                                      <v-text-field v-else dense outlined v-model="editedItem.copies" item-text="roleLink_list_id" item-value="id" persistent-hint type="number" :readonly="!inSave" :disabled="!inSave" :error="errorMsg.copies ? true : false" :error-messages=" errorMsg.copies ? 'Required' : ''" :rules="[(v) => !!v || 'required']"></v-text-field>
                                                    </v-col>
                                                    <v-col v-if="inSave" cols="12" sm="12" md="4" lg="4">
                                                      <label v-if="inSave">Date of Entry</label>
                                                      <v-menu v-if="inSave" v-model="showPicker6" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                          <v-text-field v-model="editedItem.date_of_entry" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                                        </template>
                                                        <!--<v-date-picker v-model="editedItem.date_of_entry" no-title @input="showPicker6 = false" ></v-date-picker>-->
                                                        <v-date-picker v-model="editedItem.date_of_entry" no-title :min="nowDate" :max="getEndDate"></v-date-picker>
                                                      </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Publication Year</label>
                                                      <v-menu v-model="showPickerYP" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                          <v-text-field v-model="editedItem.publicationYear" placeholder="Select Year" hint="YYYY" persistent-hint dense outlined v-on="on"></v-text-field>
                                                        </template>
                                                        <!--<v-date-picker v-model="editedItem.publicationYear" no-title type="year" @input="showPickerYP = false"></v-date-picker>-->
                                                        <v-date-picker v-model="editedItem.publicationYear" no-title type="year" :min="nowDate" :max="getEndDate"></v-date-picker>
                                                        
                                                      </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Number of Pages</label>
                                                      <v-text-field dense outlined type="number" v-model="editedItem.pages" item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Subject</label>
                                                      <v-text-field dense outlined v-model="editedItem.subject" item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Department</label>
                                                      <v-autocomplete class="text" item-text="name" item-value="id" :items="libdeptlist" v-model="editedItem.libdept" dense outlined></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                        <v-checkbox style="padding-top: 30px" v-model="editedItem.isDonated" label="Is Received From Donation?" color="black" dense hide-details></v-checkbox>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Binding Type</label>
                                                      <v-autocomplete dense outlined :items="bookFormatList" item-value="id" item-text="name" v-model="editedItem.bookFormatId" persistent-hint></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" v-if="!editedItem.isDonated">
                                                        <label>Bill </label>
                                                        <v-icon color="blue" class="mr-2" @click="bill_dialog = true">mdi-plus-box</v-icon>
                                                        <v-autocomplete :items="billlist" item-text="bill" item-value="id" v-model="editedItem.billid" persistent-hint  @input.native="billsearch('text',$event.srcElement.value)"></v-autocomplete>
                                                    </v-col>
                                                    <v-dialog v-model="bill_dialog" width="500">
                                                        <v-card>
                                                            <v-card-title class="grey lighten-2">Add Bill</v-card-title>
                                                            <v-card-text style="padding-bottom: 0px">
                                                                <label class="add-text">Bill Number</label><span style="color: red; font-weight: bolder">*</span>
                                                                <v-text-field dense outlined v-model="billnumber" item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                                                                <label>Source/vendor</label>
                                                                <v-icon color="blue" class="mr-2" @click="vendor_dialog = true">mdi-plus-box</v-icon>
                                                                <v-autocomplete :items="vendorlist" item-text="vendor" item-value="vendorid" v-model="vendor" persistent-hint @input.native="searchVendorDropdown($event.srcElement.value)"></v-autocomplete>
                                                                <label>Date of Purchase</label>
                                                                <v-menu v-model="showPicker1" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                                                  <template v-slot:activator="{ on }">
                                                                    <v-text-field v-model="purchasedate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                                                  </template>
                                                                  <v-date-picker v-model="purchasedate" no-title :min="nowDate" :max="getEndDate"></v-date-picker>
                                                                </v-menu>
                                                                <label>Book Purchase price</label>
                                                                <v-text-field dense outlined v-model="price" item-text="roleLink_list_id" item-value="id" persistent-hint type="number" @input="calbill_discount()"></v-text-field>
                                                                <label>Book Discount</label>
                                                                <v-text-field dense outlined v-model="discount" item-text="roleLink_list_id" item-value="id" persistent-hint type="number" @input="calbill_discount()"></v-text-field>
                                                                <label>Book price</label>
                                                                <v-text-field dense outlined v-model="bookprice" item-text="roleLink_list_id" item-value="id" persistent-hint type="number"></v-text-field>
                                                                <label class="add-text">PO Number</label>
                                                                <v-text-field dense outlined v-model="ponumber" item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                                                                 <label>PO Date</label>
                                                                <v-menu v-model="showPickerPO" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                                                  <template v-slot:activator="{ on }">
                                                                    <v-text-field v-model="podate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                                                  </template>
                                                                  <v-date-picker v-model="podate" no-title :min="nowDate" :max="getEndDate"></v-date-picker>
                                                                </v-menu>
                                                              </v-card-text>
                                                            <div class="text-center">
                                                                <v-spacer></v-spacer>
                                                                <v-btn class="m-3" color="error" @click="bill_dialog = false">Close</v-btn>
                                                                <v-btn class="m-3" color="success" @click="addBill()">Add</v-btn>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>

                                                     <v-dialog v-model="vendor_dialog" width="500">
                                                        <v-card>
                                                            <v-card-title class="grey lighten-2">Add Vendor</v-card-title>
                                                            <v-card-text style="padding-bottom: 0px">
                                                                <label class="add-text">Vendor Name</label>
                                                                <span style="color: red; font-weight: bolder">*</span>
                                                                <v-text-field class="text" v-model="vendorname" outlined dense clearable required></v-text-field>
                                                            </v-card-text>
                                                            <div class="text-center">
                                                                <v-spacer></v-spacer>
                                                                <v-btn class="m-3" color="error" @click="vendor_dialog = false">Close</v-btn>
                                                                <v-btn class="m-3" color="success" @click="addVendor()">Add</v-btn>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>

                                                    <!--
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Source/vendor</label>
                                                      <v-text-field dense outlined v-model="editedItem.vendor" item-text="vendor" item-value="vendor" persistent-hint></v-text-field>
                                                    </v-col>
                                                    <v-col  cols="12" sm="12" md="4" lg="4" v-if="!editedItem.isDonated">
                                                      <label>Bill Number</label>
                                                      <v-text-field dense outlined v-model="editedItem.billNo" item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4" v-if="!editedItem.isDonated">
                                                      <label>Date of Purchase</label>
                                                      <v-menu v-model="showPicker1" :close-on-content-click="false" transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                          <v-text-field v-model="editedItem.purchaseDate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.purchaseDate" no-title :min="nowDate" :max="getEndDate"></v-date-picker>
                                                      </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Book Purchase price</label>
                                                      <v-text-field dense outlined v-model="editedItem.price" item-text="roleLink_list_id" item-value="id" persistent-hint type="number" @input="cal_discount()"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Book Discount</label>
                                                      <v-text-field dense outlined v-model="editedItem.discount" item-text="roleLink_list_id" item-value="id" persistent-hint type="number" @input="cal_discount()"></v-text-field>
                                                    </v-col>                       
                                                    <v-col cols="12" sm="12" md="4" lg="4">
                                                      <label>Book price</label>
                                                      <v-text-field dense outlined v-model="editedItem.bookprice" item-text="roleLink_list_id" item-value="id" persistent-hint type="number"></v-text-field>
                                                    </v-col>-->
                                                    
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn class="button-cancel" text @click="bookitemclose">Cancel</v-btn>
                                            <v-btn class="button" @click="savebookitem">Save</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                   
                    <template v-slot:item.copies="{ item }">
                      <v-icon @click="bookitem(item)">mdi mdi-plus-circle</v-icon>
                      <v-icon v-if="item.copies" @click="editbookitem(item)">mdi mdi-pencil-box</v-icon>
                      <span>{{item.copies}}</span>
                    </template>
                    <template v-slot:item.rackInfo="{ item }">
                        <span v-if="item.mapLocation == 0"><i class="mdi mdi-check check"></i></span>
                        <span v-else> <i class="mdi mdi-close cross"></i></span>
                        <router-link :to="{ name: 'add-location', params: { bookId: item.id } }" style="text-decoration: none">
                            <v-btn small color="info">Add Location</v-btn>
                        </router-link>
                    </template>
                    <template v-slot:item.action="{ item }">
                        
                          <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
                          <v-icon @click="copybook(item)">content_copy</v-icon>
                          <v-icon v-if="!item.copies" class="mr-2" @click="deleteItem(item)"> mdi mdi-delete</v-icon>
                        
                    </template>
                    <template v-slot:item.bookItem="{ item }">
                           <v-btn small color="info" @click="routetobookitem(item.id)">Book Item</v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <template>
          <div class="text-center">
            <v-dialog v-model="newBook" width="800">
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Newly added book copies data
                </v-card-title>
                <v-card-text>
                  <v-simple-table v-if="newbook_data.length > 0" class="mt-3" style="border: 1px solid">
                    <template v-slot:default>
                      <thead>
                        <tr style="background: lightgray">
                          <th class="text-left" style="font-size: 16px">Book Title</th>
                          <th class="text-left" style="font-size: 16px">Accession Number</th>
                          <th class="text-left" style="font-size: 16px">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item1 in newbook_data" :key="item1.acc_no">
                          <td>{{ item1.title }}</td>
                          <td>{{ item1.acc_no }}</td>
                          <td>{{ item1.status }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="info" @click="newBook = false"> OK </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    publisher_dialog:false,
    title_dialog:false,
    edition_dialog:false,
    bill_dialog:false,
    vendor_dialog:false,
    publisher_short_name:"",
    publishername:"",
    titlename:"",
    editionname:"",
    vendorname:"",
    billnumber:"",
    purchasedate:"",
    ponumber : "",
    podate : "",
    vendorlist : [],
    price : 0,
    discount: 0,
    bookprice: 0,
    vendor: "",
    nowDate: '0000-01-01',    
    showPickerYP:false,
    showPicker: false,
    showPicker1: false,
    showPickerPO : false,
    showPicker5: false,
    showPicker6: false,
    bookFormatList: [],
    bookTypeList: [],
    booktypelistsearch: [],
    bookCatList: [],
    bookSeriesList: [],
    accno:"",
    isbnsearch:"",
    languageList: [],
    publisherList: [],
    publisherlistsearch: [],
    libraryList: "",
    bookList: [],
    authorList: [],
    valid: false,
    overlay: false,
    errorMsg: {
      roleLinkId: false,
    },
    snackbar_msg: "",
    color: "",
    menu1: false,
    menu2: false,
    snackbar: false,
    search: "",
    org: "",
    inSave: true,
    newBook: false,
    program_list: null,
    program: null,
    dialog: false,
    authordialog: false,
    authorName: "",
    author_description: "",
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      { text: "Actions", value: "action", sortable: false },
      { text: "Book Item(s)", value: "bookItem", sortable: false },
      {
        text: "No of Copies",
        align: "left",
        sortable: true,
        value: "copies",
      },
      {
        text: "ISBN",
        align: "left",
        sortable: true,
        value: "isbn",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Edition",
        align: "left",
        sortable: true,
        value: "edition",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "authorsname",
      },
      {
        text: "Binding Type",
        align: "left",
        sortable: true,
        value: "bookFormat",
      },
      {
        text: "Book Type",
        align: "left",
        sortable: true,
        value: "bookType",
      },
      {
        text: "Program",
        align: "left",
        sortable: true,
        value: "program_name",
      },
      {
        text: "Book Category",
        align: "left",
        sortable: true,
        value: "bookCat",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      { text: "Rack Info Added?", value: "rackInfo", sortable: false },
    ],
    bookCategoryList: [],
    roleLink_list: [],
    role_list: [],
    editedIndex: -1,
    editedItem: {
      cno:"",
      libdept: "",
      acc: "",
      w_date: "",
      w_no: "",
      p_place: "",
      seriesId: "",
      program: "",
      vendor: "",
      isbn: "",
      title: "",
      description: "",
      authors: null,
      edition: "",
      keywords:"",
      subject: "",
      place: "",
      volume: "",
      year: "",
      pages: null,
      copies: null,
      price: 0,
      bookprice: 0,
      discount: 0,
      billNo: "",
      authorId: null,
      isDonated: "",
      language: null,
      purchaseDate: null,
      publicationDate: null,
      publicationYear: null,
      bookFormat: null,
      bookType: null,
      bookCat: null,
      publisher: null,
      library: null,
      isactive: false,
      id: "",
      authorText: false,
      publisherText: null,
      date_of_entry:null
    },
    publisherText: false,
    defaultItem: {
      seriesId: "",
      program: "",
      isbn: "",
      title: "",
      description: "",
      authors: null,
      edition: "",
      keywords:"",
      subject:"",
      place:"",
      volume: "",
      year: "",
      pages: null,
      copies: null,
      price: 0,
      bookprice: 0,
      discountPrice: 0,
      billNo: "",
      isDonated: "",
      authorId: null,
      purchaseDate: null,
      publicationDate: null,
      publicationYear: null,
      bookFormat: null,
      bookType: null,
      bookCat: null,
      publisher: null,
      library: null,
      language: null,
      isactive: false,
      id: "",
      date_of_entry:null
    },
    searchtitle:"",
    allBookInfo:[],
    titlevalue:"",
    book_authors: true,
    img: null,
    isImg: false,
    newbook_data: [],
    libdeptlist: [],
    rackData: [
      {
        rackName: "R1",
        blockName: "B1",
        category: "Comp",
        capacity: 5,
        available: 5,
      },
      {
        rackName: "R1",
        blockName: "B2",
        category: "Comp",
        capacity: 3,
        available: 3,
      },
      {
        rackName: "R1",
        blockName: "B3",
        category: "Comp",
        capacity: 2,
        available: 2,
      },
      {
        rackName: "R2",
        blockName: "B1",
        category: "mech",
        capacity: 5,
        available: 5,
      },
    ],
    bookCopies: 2,
    remaning: 2,
    editionlist : [],
    billlist : [],
    departmentlist: [],
    autherlist: [],
    titlelist: [],
    isbnlist: [],
    serieslist: [],
    selectedpublisher: "ALL",
    selecteddepartment: "ALL",
    selectedauther: "ALL",
    selectedtitle: "ALL",
    selectedisbn: "ALL",
    selectedseries: "ALL",
    selectedbooktype:"ALL",
    editcopydialog:false
  }),
  computed: {
    getEndDate() {
     var endDate = new Date();
     return endDate.toISOString().slice(0,10)
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Book" : "Edit Book";
    },
  },

  mounted() {
    this.onLoad();
    this.editedItem.date_of_entry = new Date().toISOString().slice(0, 10)
  },

  watch: {
    dialog() {
      this.img = null;
      this.isImg = false;
      this.book_authors = false;
      this.publisherText = false;
    },
  },

  methods: {
    addpubliasher() {
        this.publishername = this.publishername.trim();
        this.publisher_short_name = this.publisher_short_name.trim();
        const data = {
            publisher_name: this.publishername,
            publisherShortName: this.publisher_short_name,
            publisherDisplayOrder:1
        };
        if (this.publishername && this.publisher_short_name && this.publishername!="" && this.publisher_short_name!=""){
            axios
              .post("/admin/addPublisher", data)
              .then((res) => {
                if (res.data.msg == "success") {
                    this.publishersearch(this.publishername);
                    this.publisher_dialog = false;
                    this.publishername =  "";
                    this.publisher_short_name = "";
                    this.showSnackbar("green", "Publisher Added Successfully.");
                } else {
                  this.showSnackbar("#b71c1c", res.data.msg);
                }
              })
              .catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                console.log(error);
              })
              .finally(() => {});
          } else {
            this.showSnackbar("#b71c1c", "Please enter required fields");
            this.$refs.form.validate();
          }
    },

    addTitle() {
        this.titlename = this.titlename.trim();
        const data = {
            titlename: this.titlename,
        };
        if (this.titlename && this.titlename!=""){
            axios
              .post("/admin/addTitle", data)
              .then((res) => {
                if (res.data.msg == "success") {
                    this.titlesearch(this.titlename)
                    this.titlename = "";
                    this.title_dialog = false;
                    this.showSnackbar("green", "Title Added Successfully.");
                } else {
                  this.showSnackbar("#b71c1c", res.data.msg);
                }
              })
              .catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                console.log(error);
              })
              .finally(() => {});
          } else {
            this.showSnackbar("#b71c1c", "Please enter required fields");
            this.$refs.form.validate();
          }
    },

    addEdition() {
        this.editionname = this.editionname.trim();
        const data = {
            editionname: this.editionname,
        };
        if (this.editionname && this.editionname!=""){
            axios
              .post("/admin/addEdition", data)
              .then((res) => {
                if (res.data.msg == "success") {
                    this.editionsearch(this.editionname)
                    this.edition_dialog = false;
                    this.editionname = "";
                    this.showSnackbar("green", "Edition Added Successfully.");
                } else {
                  this.showSnackbar("#b71c1c", res.data.msg);
                }
              })
              .catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                console.log(error);
              })
              .finally(() => {});
          } else {
            this.showSnackbar("#b71c1c", "Please enter required fields");
            this.$refs.form.validate();
          }
    },

    addVendor() {
        this.vendorname = this.vendorname.trim();
        const data = {
            vendorname: this.vendorname,
        };
        if (this.vendorname && this.vendorname!=""){
            axios
              .post("/admin/addVendor", data)
              .then((res) => {
                if (res.data.msg == "success") {
                    this.searchVendorDropdown(this.vendorname)
                    this.vendor_dialog = false;
                    this.vendorname = "";
                    this.showSnackbar("green", "Vendor Added Successfully.");
                } else {
                  this.showSnackbar("#b71c1c", res.data.msg);
                }
              })
              .catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                console.log(error);
              })
              .finally(() => {});
          } else {
            this.showSnackbar("#b71c1c", "Please enter required fields");
            this.$refs.form.validate();
          }
    },
    

    addBill() {
        this.billnumber = this.billnumber.trim();
        const data = {
            billnumber: this.billnumber,
            price : this.price,
            bookprice : this.bookprice,
            discount : this.discount,
            vendor : this.vendor,
            purchasedate : this.purchasedate,
            ponumber : this.ponumber,
            podate : this.podate,

        };
        if (this.billnumber && this.billnumber!=""){
            axios
              .post("/admin/addBill", data)
              .then((res) => {
                if (res.data.msg == "success") {
                    this.billsearch('id', res.data.billid);
                    this.bill_dialog = false;
                    this.billnumber = "";
                    this.price = 0;
                    this.bookprice = 0;
                    this.discount = 0;
                    this.vendor = "";
                    this.purchasedate = "";
                    this.ponumber = "";
                    this.podate = "";
                    this.showSnackbar("green", "Bill Added Successfully.");
                } else {
                  this.showSnackbar("#b71c1c", res.data.msg);
                }
              })
              .catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                console.log(error);
              })
              .finally(() => {});
          } else {
            this.showSnackbar("#b71c1c", "Please enter required fields");
            this.$refs.form.validate();
          }
    },


    titlesearch(value) {
      this.searchtitle = value;
      if (value != "") {
        var params = { text: value, seaechbyacc: "title" };
        axios
          .post("/IssueBook/getsearchbytitleBookOnly", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.allBookInfo = res.data.allBookInfo;
            }
          })
      }
    },

    publishersearch(value){
      if (value != "") {
         var params = { text: value};
         axios
          .post("/IssueBook/getsearchbypublisher", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.publisherList = res.data.publisherList;
              this.publisherlistsearch = res.data.publisherList;
              this.selectedpublisher="ALL";
              this.publisherlistsearch.push({publisher: 'ALL', text: ''});
            }
          })
      }
    },


    editionsearch(value){
      if (value != "") {
        var params = { text: value};
        axios
          .post("/IssueBook/getsearchbyedition", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.editionlist = res.data.editionlist;
            }
          })
      }
    },

    billsearch(type, value){
      if (value != "") {
        var params = { text: value, type : type};
        axios
          .post("/IssueBook/getsearchbybill", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.billlist = res.data.billlist;
            }
          })
      }
    },

    searchTitleDropdown(value) {
      if (value != "") {
        var params = { text: value, seaechbyacc: "title" };
        axios
          .post("/IssueBook/getsearchbytitleBookOnly", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.allBookInfo = res.data.allBookInfo;
            }
          })
      }
    },

    searchVendorDropdown(value) {
      if (value != "") {
        var params = { text: value};
        axios
          .post("/IssueBook/getsearchbyVendor", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.vendorlist = res.data.vendorlist;
            }
          })
      }
    },

    routetobookitem(id){
      let routeData = this.$router.resolve({name: 'book-item', query: {bookId: id}});
      window.open(routeData.href, '_blank');
    },

    searchByA_no() {
      if(typeof this.searchtitle  === 'object')
         this.searchtitle = this.searchtitle.displayname;
      var params = {
        selecteddepartment: this.selecteddepartment,
        selectedpublisher: this.selectedpublisher,
        selectedseries: this.selectedseries,
        selectedbooktype : this.selectedbooktype,
        Acc_no:this.accno,
        ISBN : this.isbnsearch,
        title : this.searchtitle
      };
      this.overlay = true;
      axios
        .post("/Librarian/getBookDataWithfilter",params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.bookList = res.data.book_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
        });
    },

    fetchBookInfo() {
        if (this.editedItem.isbn !== "") {
          axios
            .get(
              "https://cors-anywhere.herokuapp.com/https://www.googleapis.com/books/v1/volumes?q=" +
                this.editedItem.isbn
            ).then((res) => {
              this.editedItem.title = res.data.items[0].volumeInfo.title;
              this.editedItem.description = res.data.items[0].volumeInfo.description;
              this.editedItem.pages = res.data.items[0].volumeInfo.pageCount;
              this.editedItem.publicationDate = res.data.items[0].volumeInfo.publishedDate;
              let auth = res.data.items[0].volumeInfo.authors;
              if (auth.length > 0) {
                this.book_authors = true;
                for (let i = 0; i < auth.length; i++) {
                  if (this.editedItem.authors == null) {
                    this.editedItem.authors = auth[i];
                  } else {
                    this.editedItem.authors =
                      this.editedItem.authors + "," + auth[i];
                  }
                }
                this.editedItem.authorText = true;
              } else {
                this.book_authors = false;
                this.editedItem.authorText = false;
              }

              if (res.data.items[0].volumeInfo.publisher !== null) {
                this.publisherText = true;
                this.editedItem.publisherText = this.publisherText;
                this.editedItem.publisherId =
                  res.data.items[0].volumeInfo.publisher;
              } else {
                this.publisherText = false;
                this.editedItem.publisherText = this.publisherText;
              }
              this.img = res.data.items[0].volumeInfo.imageLinks.smallThumbnail;
              if (this.img) {
                this.isImg = true;
              } else {
                this.isImg = false;
              }
           });
        } else {
          this.editedItem = {};
        }
    },
  
    cal_discount() {
        if(this.editedItem.discount==0){
            this.editedItem.bookprice = this.editedItem.price;
        }else{
            var discount = (this.editedItem.price*this.editedItem.discount)/100;
            this.editedItem.bookprice = (this.editedItem.price-discount);
        }
    },

    calbill_discount() {
        if(this.discount==0){
            this.bookprice = this.price;
        }else{
            var discount = (this.price*this.discount)/100;
            this.bookprice = (this.price-discount);
        }
    },

    getavalableisbn() {
      var params = { seriesid: this.editedItem.seriesId };
      this.overlay = true;
      axios.post("/Librarian/getnewacc_no_track", params).then((res) => {
        if (res.data.msg == "200") {
          this.editedItem.isbn = res.data.isbn;
          this.overlay = false;
        } else {
          this.overlay = false;
        }
      });
    },

    addBook() {
      this.inSave = true;
      this.dialog = true;
      this.editedIndex=-1;
      this.$refs.form.reset();
    },

    editItem(item) {
      console.log("item---", item.isbn);
      this.inSave = false;
      this.editedIndex = this.bookList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.searchTitleDropdown(this.editedItem.title);
      this.editionsearch(this.editedItem.edition);
      this.dialog = true;
      var name = item.role;
      for (let x in this.role_list) {
        if (this.role_list[x].name == name) {
          this.editedItem.role = this.role_list[x].name;
          this.editedItem.roleId = this.role_list[x].id;
          break;
        }
      }
    },

    onLoad() {
        this.overlay = true;
        axios
          .post("/Librarian/getBookDataNew")
          .then((res) => {
            if (res.data.msg == "200") {
              this.overlay = false;
              this.program_list = res.data.programList;
              this.libdeptlist = res.data.librarydeptlist;
              this.bookTypeList = res.data.bookTypeList;
              this.bookFormatList = res.data.bookFormatList;
              this.bookCatList = res.data.bookCatList;
              this.languageList = res.data.languageList;
              this.libraryList = res.data.lib_list;
              this.authorList = res.data.authorList;
              this.bookSeriesList = res.data.bookSeriesList;
              this.booktypelistsearch = res.data.bookTypeList;
              this.departmentlist = res.data.librarydeptlist;
              this.serieslist = res.data.bookSeriesList;
              console.log(this.serieslist);
              console.log(this.serieslist[0].seriesId);
              this.selectedseries=this.serieslist[0].seriesId;
              this.editedItem.library=res.data.libid.id;

              if(this.departmentlist.length > 1){
                  this.selecteddepartment="ALL";
                  this.departmentlist.push({name: 'ALL', text: ''});
              }else if(this.departmentlist.length > 0){
                  this.selecteddepartment= this.departmentlist[0].id;
              }

              if(this.booktypelistsearch.length > 1){
                  this.selectedbooktype="ALL";
                  this.booktypelistsearch.push({bookType: 'ALL', text: ''});
              }else if(this.booktypelistsearch.length > 0){
                  this.selectedbooktype= this.booktypelistsearch[0].id;
              }

              if(this.serieslist.length > 1){
                  this.selectedseries="ALL";
                  this.serieslist.push({seriesName: 'ALL', text: ''});
              }else if(this.serieslist.length > 0){
                  this.selectedseries= this.serieslist[0].seriesId;
              }

              this.selectedpublisher="ALL";
              this.publisherlistsearch.push({publisher: 'ALL', text: ''});
            }
          }).catch((error) => {
            this.showSnackbar("#b71c1c", error); // show snackbar
            window.console.log(error);
              this.overlay = false;
          }) .finally(() => {});
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedItem.library == null ||this.editedItem.library == "") {
         this.showSnackbar("#b71c1c", "Please Select Library !!!");
      } else if (this.editedItem.seriesId == null ||this.editedItem.seriesId == "") {
         this.showSnackbar("#b71c1c", "Please Select Series !!!");
      } else if (this.editedItem.isbn == null || this.editedItem.isbn == "") {
         this.showSnackbar("#b71c1c", "Please Enter ESBN !!!");
      } else if (this.editedItem.titleid == null ||this.editedItem.titleid == "") {
         this.showSnackbar("#b71c1c", "Please Select Title !!!");
      } else if (this.editedItem.editionid == null ||this.editedItem.editionid == "") {
         this.showSnackbar("#b71c1c", "Please Select Edition !!!");
      } else {
          if (this.editedIndex > -1) {
              if (this.$refs.form.validate()) {
                axios
                  .post("/Librarian/editBookNew", this.editedItem)
                  .then((res) => {
                    if (res.data.msg == "success") {
                        this.overlay=false;
                        this.showSnackbar("#4caf50", "Book Updated Successfully..."); 
                        if (res.data.isbnExistMsg) {
                            this.showSnackbar("#b71c1c", res.data.isbnExistMsg); 
                        }
                    } else {
                        this.overlay=false;
                        this.showSnackbar("#b71c1c", res.data.msg); 
                    }
                    this.searchByA_no();
                  })
                  .catch((error) => {
                    window.console.log(error);
                    this.overlay=false;
                  });
                  this.close();
              }
          } else {
              this.overlay=true;
              axios
                .post("/Librarian/saveBookNew", this.editedItem)
                .then((res) => {
                  if (res.data.msg == "success") {
                    this.overlay=false;
                    this.showSnackbar("#4caf50", "Book Added Successfully!!!");            
                    this.isbnsearch=this.editedItem.isbn;
                    this.searchtitle="";
                    this.accno="";
                    this.selectedpublisher="";
                    this.selectedseries="";
                    this.selecteddepartment="";
                    this.selectedbooktype = "";
                    this.searchByA_no();
                    this.close();
                  } else if (res.data.status.code == "NA") {
                    this.overlay=false;
                    this.showSnackbar("#b71c1c", "Book Already Present!!!"); 
                  
                  } else {
                    this.overlay=false;
                    this.showSnackbar("#b71c1c", res.data.msg);
                  }
                })
                .catch((error) => {
                  window.console.log(error);
                  this.overlay=true;
                 
                });
                
          }
      }
    }, 

    activeOn(item) {
      axios
        .post("/admin/rackIsactive", item)
        .then(() => {
          this.showSnackbar("#4CAF50", "Rack updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    isValidated() {
        if (this.editedItem.roleLinkId) {
          return true;
        } else {
            if (!this.editedItem.roleLinkId) {
              this.errorMsg.roleLinkId = true;
            }
            return false;
        }
    },

    addAuthor() {
      if (this.authorName && this.author_description) {
        this.authorName = this.authorName.trim();
        this.author_description = this.author_description.trim();
      }
      const data = {
        author_name: this.authorName,
        author_des: this.author_description,
      };
      if (this.authorName) {
        axios
          .post("/admin/addAuthor", data)
          .then((res) => {
            this.authorList = res.data.author_list;
            if (res.data.msg == "success") {
              this.authordialog = false;
                this.showSnackbar("green", "Auther Added Successfully.");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => {});
      } else {
        this.showSnackbar("#b71c1c", "Please enter required fields");
        this.$refs.form.validate();
      }
    },

    bookitem(item) {
      this.getavalableacc(item);
      this.editcopydialog=true;
      this.inSave = true;
      this.editedIndex = this.bookList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.billsearch('id', this.editedItem.billid);
    },

    editbookitem(item){
      this.getavalableacc(item);
      this.inSave = false;
      this.editcopydialog=true;
      this.editedIndex = this.bookList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.billsearch('id', this.editedItem.billid);
    },

    bookitemclose() {
      this.editcopydialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    getavalableacc(item) {
      var params = { seriesid: item.seriesId };
      this.overlay = true;
      axios.post("/Librarian/getnewacc_no_track", params).then((res) => {
        if (res.data.msg == "200") {
          this.editedItem.acc = res.data.acc_no;
          this.overlay = false;
        } else {
          this.overlay = false;
        }
      });
    },

copybook(item){      
      if (confirm("Are You Sure You Want To Copy Book?")) {                 
        const data = {            
            id: item.id,
        };
        axios
            .post("/Librarian/copyBook", data)
            .then((res) => {
                if (res.data.msg == "200") {
                    this.showSnackbar("#4CAF50", "Book Copy Successfully  !!");
                }else {
                    this.showSnackbar("#b71c1c", res.data.msg);
                }
                this.searchByA_no();
            }).catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                window.console.log(error);
            });
      }
    },
    deleteItem(item){      
      if (confirm("Are You Sure You Want To Delete Book Details?")) {                 
        const data = {            
            id: item.id,
        };
        axios
            .post("/Librarian/deleteBook", data)
            .then((res) => {
                if (res.data.msg == "200") {
                    this.showSnackbar("#4CAF50", "Book Deleted Successfully  !!");
                }else if(res.data.msg == "401"){
                    this.showSnackbar("#b71c1c", res.data.status);
                }else if(res.data.msg == "exp"){
                    this.showSnackbar("#b71c1c", "Can not be Deleted, Already In Use!!!");
                }
                this.searchByA_no();
            }).catch((error) => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                window.console.log(error);
            });
      }
    },

    savebookitem(){
        this.editedItem.copies = parseInt(this.editedItem.copies);
        this.editedItem.pages = parseInt(this.editedItem.pages);
        this.editedItem.purchaseDate = moment(String(this.editedItem.purchaseDate)).format("DD-MM-YYYY");
        if (!this.inSave) {
          if (!this.editedItem.isDonated && (this.editedItem.billid == null ||this.editedItem.billid == "")) {
             this.showSnackbar("#b71c1c", "Please Select Bill !!!");
          } else {
            if (this.$refs.form.validate()) {
              axios
                .post("/Librarian/editBookItemNew", this.editedItem)
                .then((res) => {
                  if (res.data.msg == "success") {
                    this.overlay=false;
                    this.showSnackbar("#4caf50", "Book Item Updated Successfully..."); 
                    if (res.data.isbnExistMsg) {
                      this.showSnackbar("#b71c1c", res.data.isbnExistMsg); 
                    }
                  } else {
                    this.overlay=false;
                    this.showSnackbar("#b71c1c", res.data.msg);
                  }
                  this.searchByA_no();
                })
                .catch((error) => {
                  window.console.log(error);
                  this.overlay=false;
                });
                this.bookitemclose();
            }
          }
        } else {
          if (this.editedItem.editcopies == null ||this.editedItem.editcopies == "") {
             this.showSnackbar("#b71c1c", "Please Enter Number of Copies !!!");
          } else if (this.editedItem.billid == null ||this.editedItem.billid == "") {
             this.showSnackbar("#b71c1c", "Please Select Bill !!!");
          } else {
            this.overlay=true;
            axios
              .post("/Librarian/saveBookItem", this.editedItem)
              .then((res) => {
                if (res.data.msg == "success") {
                  this.overlay=false;
                  this.newbook_data = res.data.book_data;
                  this.newBook = true;
                  this.showSnackbar("#4caf50", "Book Item Added Successfully!!!"); 
                }else {
                  this.overlay=false;
                  this.showSnackbar("#b71c1c", res.data.msg);
                }
              })
              .catch((error) => {
                window.console.log(error);
                this.overlay=true;
              });
              this.bookitemclose();
          }
        }
      }
    },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}
.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}
.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}
.col-sm-12.col-md-4.col-lg-4.col-12 {
    padding-top: 0px;
    padding-bottom: 0px;
}

</style>